<template>
<!-- 日程 -->
<div :style="{'--themecolor':themecolors}" v-if="show_schedule">
    <div class="schedule" id="scroll" @scroll='showOut' ref="schendulScroll">
        <div style="min-height:95%;">
            <div class="schedule-top">
                <div class="top">
                    <van-search shape="round" v-model="value" placeholder="请输入讲者或主持姓名" @search="scheduleSearch" />
                </div>
                <div class="tabs">
                    <div class="tab-box" v-for="(i,j) in date_line" :key="j" :class="{'tab-active': i.id == meeting_place_id}"
                    @click="active_meeting(i)">
                        <span class="week" v-if="i.type == 1">{{ i.week }}</span>
                        <span class="tab-title" :style="i.type == 1 ? 'margin-top:2px':'font-size:13px'">{{i.title}}</span>
                    </div>
                </div>
            </div>
            <div class="bottom">
                <van-loading :color="themecolors" :text-color="themecolors" v-if="Loading"/>
                <div class="null" v-else-if="schedule_place_config.length == 0">
                    <div class="null-title">
                        <p>暂无日程</p>
                    </div>
                    <van-empty :image="require('../../assets/attend/none-img.svg')" />
                </div>
                <template v-else> 
                    <div class="bottom_main">
                        <div class="session" v-for="(item,index) in schedule_place_config" :key="index" >
                            <div :class="{'session-title-show':!item.unfold,'session-title':item.unfold}">
                                <div class="title-right" @click="unfoldClick(item)">
                                    <span>详情</span>
                                    <img src="@/assets/schedule/toggle.svg">
                                </div>
                                <p>{{item.venue_title}}</p>
                            </div>
                            <div class="session-main" v-if="!item.unfold" :index="index" ref="schendul" @scroll='showsessionmain'>
                                <div class="session-item" v-for="(i,j) in item.agendum_info" :key="j" >
                                    <div class="pad-box" :class="{'live-conduct':i.live == true && item.studio_info}">
                                        
                                        <div class="item-title">
                                            <div class="title-left">
                                                <span>{{i.start_time_format}}</span>
                                                <span class="interval">-</span>
                                                <span>{{i.end_time_format}}</span>
                                            </div>
                                            <img src="@/assets/schedule/time-active.svg" alt="" v-if="i.live == true && item.studio_info">
                                            <img src="@/assets/schedule/time.svg" alt="" v-else>
                                        </div>
                                        <div class="item-main">
                                            <div class="meeting-title" @click="viewDetail(i,item.timeline,item)">
                                                <p>{{i.agendum_title}}</p>
                                            </div>
                                            <!-- <div class="live-btn" @click="liveUrl(item)">
                                                <img src="@/assets/schedule/live-conduct.gif" class="live-gif" alt="" v-if="i.live && item.studio_info">
                                                <img src="@/assets/schedule/paly-end.svg" v-else alt="">
                                                <span>直播</span>
                                            </div> -->
                                            <div class="item_speaker" v-if="i.people_library[1]">
                                                <span v-for="(j,k) in i.people_library[1]" :key="k" class="speaker_name"><span >{{ j.type_name }}：</span>{{j.name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="session-main-two" v-else>
                                <div class="session-item"  :class="{'live-conduct':i.live == true && item.studio_info}" v-for="(i,j) in item.agendum_info" :key="j">
                                    <div class="item-title">
                                        <img src="@/assets/schedule/time-active.svg" alt="" v-if="i.live == true && item.studio_info">
                                        <img src="@/assets/schedule/time.svg" alt="" v-else>
                                        <div class="title-right">
                                            <span>{{i.start_time_format}}</span>
                                            <span class="interval">-</span>
                                            <span>{{i.end_time_format}}</span>
                                        </div>
                                    </div>
                                    <div class="item-main">
                                        <div class="item-main-box">
                                            <div class="meeting-title"  @click="viewDetail(i,item.agendum_info,item)">
                                                <p>{{i.agendum_title}}</p>
                                            </div> 
                                            <div class="item_speaker" >
                                                <span v-if="i.people_library[1]"><span v-for="(j,k) in i.people_library[1]" :key="k" class="speaker_name"><strong v-if="(k != 0 && j.type_name != i.people_library[1][k - 1].type_name) || k ==0">{{ j.type_name }}：</strong> {{j.name}}</span></span>
                                                <div class="meeting-bottom">
                                                    <div class="live-btn" @click="liveUrl(item)">
                                                        <img src="@/assets/schedule/live-conduct.gif" class="live-gif" alt="" v-if="i.live && item.studio_info">
                                                        <img src="@/assets/schedule/paly-end.svg" v-else alt="">
                                                        <span>直播</span>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <div v-if="iscover" class="cover_img">
                    <div class="img_item">
                        <img :src="coverurl">
                    </div>
                    <div class="btns" @click="detailClick">进入详情</div>
                </div>
            </div>
        </div>
        <Support></Support>
    </div>
    <sideBar></sideBar>
</div>
<Newdetail v-else></Newdetail>
</template>
<script>
import { mapState } from 'vuex'
import sideBar from '@/components/sidebar';
import Support from '@/components/support';
import Newdetail from './newdetail.vue'
export default {
    components:{
        sideBar,
        Support,
        Newdetail
    },
    data(){
        return{
            scrollTop:0,
            value:"",
            unfold:false,
            Loading:false,
            params:{},
            meeting_place_id:null,
            schedule_library_id: null,
            schedule_place_config:[],
            date_line:[],
            oldid:null,
            iscover: false,
            coverurl: '',
            arrow_up: false,
            showAttention: false
        }
    },
    created(){
        this.params = this.$route.query;
        this.get_schedule_timetable_list();
        if(sessionStorage.getItem('user_header') == 1 && localStorage.getItem('wxmessage')){
            this.showAttention = true
        }else{
            this.showAttention = false
        }
    },
    computed: {
        ...mapState(['themecolors', 'show_schedule'])
    },
    watch:{
        show_schedule(val){
            if(val){
                this.$nextTick(()=>{
                    this.$refs.schendulScroll.scrollTop = this.scrollTop
                    let i=0
                    this.schedule_place_config.forEach((item,index)=>{
                        if(item.unfold){
                            i++
                            return
                        }else{
                            this.$refs.schendul[index-i].scrollLeft=item.scrollLeft
                        }
                       
                    })
                })
            }
        }
    },
    mounted(){
    },
    methods:{
        showsessionmain(e){
            this.schedule_place_config[$(e.target).attr('index')].scrollLeft=e.target.scrollLeft  
        },
        // 监听滚动的位置
        showOut(e){
            this.scrollTop = e.target.scrollTop;
        },
        active_meeting(val) {
            this.meeting_place_id = val.id, 
            // this.schedule_library_id = val.schedule_library_id
            this.get_schedule_timetable_infos(this.meeting_place_id)
        },
        //关注或取消关注日程
        async attentionSchedule(val, index, valIndex){
            if(this.$cookies.isKey('microtoken')){
                this.$toast('请用微信浏览器打开！')
                return
            }
            let res
            if( this.schedule_place_config[index].agendum_info[valIndex].follow_status == 0 ){
                res = await this.$store.dispatch('add_follow_the_schedule', {
                    id: val.id,
                    site_id: this.$route.query.site_id,
                    page_id: this.$route.query.page_id,
                    module_id: this.$route.query.module_id
                })
            }else{
                res = await this.$store.dispatch('delete_follow_the_schedule', val.id)
            }
            if(res.data.code == 200){
                if(this.schedule_place_config[index].agendum_info[valIndex].follow_status == 0){
                    this.schedule_place_config[index].agendum_info[valIndex].follow_status = 3
                }else{
                    this.schedule_place_config[index].agendum_info[valIndex].follow_status = 0
                }
                this.$toast(res.data.message)
            }else{
                this.$toast(res.data.message)
            }
        },
        liveUrl(item){
            if(!item.studio_info){
                this.$toast({
                  message: '当前会议未添加直播间!',
                });
            }else{
                let temps = item.studio_info
                if (temps.type == 1) {
                let obj = {
                    studio_id: temps.studio_id,
                    site_id: this.$cookies.get('site_id'),
                    form_id: this.$cookies.get("form_user_id"),
                };
                this.$store
                    .dispatch("get_user_form_identity", obj)
                    .then((res) => {
                        if (res.data.code == 200) {
                        location.href =
                            temps.live_studio_url + "?liguserinfo=" + res.data.data.token;
                        }
                        if (res.data.code == 206) {
                        location.href = temps.live_studio_url;
                        }
                    })
                    .catch((err) => {
                        location.href = temps.live_studio_url;
                    });
                    return
                }
                location.href = temps.live_studio_url;
            }
            
        },
        /** 转日期格式 */
        getLocalTime(time) {     
            var now = new Date(time);
            var year=now.getFullYear(); 
            var month=now.getMonth()+1; 
            var date=now.getDate(); 
            var hour=now.getHours(); 
            var minute=now.getMinutes(); 
            var second=now.getSeconds(); 
            return year+"-"+month+"-"+date+" "+hour+":"+minute+":"+second;   
        },
        // 转日期格式
        timestampToTime (time) {
            // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
            let date = new Date(time * 1000) 
            let y = date.getFullYear()
            let MM = date.getMonth() + 1
            MM = MM < 10 ? ('0' + MM) : MM
            let d = date.getDate()
            d = d < 10 ? ('0' + d) : d
            let h = date.getHours()
            h = h < 10 ? ('0' + h) : h
            let m = date.getMinutes()
            m = m < 10 ? ('0' + m) : m
            let s = date.getSeconds()
            s = s < 10 ? ('0' + s) : s
            return MM + '-' + d
        },
        timestampToweek (time) {
            // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
            let date = new Date(time * 1000) 
            let y = date.getFullYear()
            let MM = date.getMonth() + 1
            MM = MM < 10 ? ('0' + MM) : MM
            let d = date.getDate()
            d = d < 10 ? ('0' + d) : d
            let h = date.getHours()
            h = h < 10 ? ('0' + h) : h
            let m = date.getMinutes()
            m = m < 10 ? ('0' + m) : m
            let s = date.getSeconds()
            s = s < 10 ? ('0' + s) : s
            return y + '-' + MM + '-' + d
        },
        getWeek(dateString) {
            let dateArray = dateString.split("-");
            let date = new Date(dateArray[0], parseInt(dateArray[1] - 1), dateArray[2]);
            return "周" + "日一二三四五六".charAt(date.getDay());
        },
        /** 时间段范围 */
        isDuringDate(beginDateStr, endDateStr) {
            var curDate = new Date(),
                beginDate = new Date(beginDateStr),
                endDate = new Date(endDateStr);
            if (curDate >= beginDate && curDate <= endDate) {
                return true;
            }
            return false;
        },
        get_schedule_timetable_list(){
            this.Loading = true;
            this.$store.dispatch("get_schedule_library_info_list",{
                page_id:this.params.site_id,
                // module_id:this.params.module_id,
            }).then(res=>{
                this.Loading = false;
                if(res.data.code == 200) {
                    document.title = res.data.data.module_title ? res.data.data.module_title : (sessionStorage.getItem('wei-title') || '轻微站');
                }
                if(res.data.code == 200 && res.data.data.list.length != 0){
                    const todeday = new Date(new Date().toLocaleDateString()).getTime()
                    const endday = new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1
                    let index = res.data.data.list.findIndex(item => todeday  <= Number(item.title) * 1000 && Number(item.title) * 1000 <= endday)
                    if (index == -1) {
                        index = 0
                    }
                    res.data.data.list.forEach((item)=>{
                        if(item.type == 1){
                             let week = this.timestampToweek(item.title)
                            item.title = this.timestampToTime(item.title)
                            item['week'] = this.getWeek(week)
                        }
                    })
                    this.date_line = res.data.data.list;
                    this.meeting_place_id = this.date_line[index].id;
                    if(this.date_line[index].cover_url){
                        this.iscover = true
                        this.coverurl = this.date_line[index].cover_url
                    }else{
                        this.iscover = false
                        this.coverurl = this.date_line[index].cover_url
                    }
                    // if(sessionStorage.getItem('schedule_library_id') && sessionStorage.getItem('meeting_place_id')) {
                    //     // this.schedule_library_id = sessionStorage.getItem('schedule_library_id')
                    //     this.meeting_place_id = sessionStorage.getItem('meeting_place_id')
                    // } else {
                        // this.meeting_place_id = this.date_line[index].id
                        // this.schedule_library_id = this.date_line[index].schedule_library_id
                    // }
                    
                    this.get_schedule_timetable_infos(this.meeting_place_id);
                }
            })
        },
        get_schedule_timetable_infos(meeting_place_id){
            if(this.oldid == this.meeting_place_id){
                return
            }
            this.Loading = true;
            this.$store.dispatch("get_schedule_venue_info_list",{
                library_id:meeting_place_id,
                // schedule_library_id:schedule_library_id,
                // module_id:this.params.module_id,
            }).then(res=>{
                this.Loading = false;
                if(res.data.code == 200){
                    this.oldid = meeting_place_id;
                    this.schedule_place_config = res.data.data
                    let arr = res.data.data ? res.data.data : [];
                    arr.forEach((item,index) => {
                        item.scrollLeft=0
                        item.unfold = false
                        item.agendum_info.forEach((i,ind) => {
                            i.live = this.isDuringDate(i.start_time  * 1000,i.end_time  * 1000);
                            if(i.live == true){
                                if(item?.studio_info != ""){
                                    arr[index].agendum_info.unshift(i)
                                    arr[index].agendum_info.splice(ind+1,1)
                                }
                            }
                        })
                    });
                    if(this.schedule_place_config.length == 1){
                        this.schedule_place_config.forEach(item=>{
                            item.unfold = true
                        })
                    }
                }
            })
        },
        unfoldClick(item){
            this.schedule_place_config.forEach(i=>{
                i.id != item.id ? i.unfold = false : '';
            })
            item.unfold = !item.unfold;
            this.$forceUpdate();  
        },
        scrollLeft(){
            this.scroll_left = $('.session-main').scrollLeft();
        },
        listenerFunction(e) {
            document.addEventListener('scroll', this.scrollLeft, true);
        },
        viewDetail(i,timeline,item){
            let scheduleDate = '';
            this.date_line.forEach(i => i.meeting_place_id == this.meeting_place_id ? scheduleDate = i: '' );
            // window.sessionStorage.setItem("scheduleDate",JSON.stringify(scheduleDate))
            // window.sessionStorage.setItem("scheduleCurrentItemConfig",JSON.stringify(item))
            window.sessionStorage.setItem("scheduleTimelineCurrentItem",JSON.stringify(item))
            sessionStorage.setItem('wei_live_message', JSON.stringify(item.studio_info))
            // window.sessionStorage.setItem("scheduleTimeline",JSON.stringify(timeline))
                this.$router.push({name: 'scheduleInfo', query: {
                    id:i.venue_id,
                }});
            // if(this.$route.query.singePage){
            //     this.$router.push({name: 'scheduleInfo', query: {
            //         site_id:this.params.site_id,
            //         singePage: 'singePage',
            //         site_url: this.$route.query.site_url,
            //         backtrack: 'backtrack'
            //     }});
            // } else {
            //     this.$store.commit('changge_show_schedule', false)
            //     // this.$router.push(({name:'scheduleInfo',query: this.params}));
            // }
        },
        scheduleSearch(value) {
            let obj = JSON.parse(JSON.stringify(this.$route.query))
            obj.module_id = window.sessionStorage.getItem('module_id')
            this.$store.commit('changeschedulevalue', value)
            if(this.$route.query.singePage) {
                obj.singePage = 'singePage'
                obj.backtrack = 'backtrack'
                this.$router.push({name:'attend',query:obj});
            } else {
                this.$router.push({name:'attend',query:obj});
            }
        },
        detailClick() {
            this.iscover = false
        }
    },
    beforeDestroy () {
        // let meeting_place_id = null
        // let schedule_library_id = null
        // if(sessionStorage.getItem('meeting_id') && sessionStorage.get('schedule_library_id')) {
        //     meeting_place_id = sessionStorage.getItem('meeting_id')
        //     schedule_library_id = sessionStorage.getItem('schedule_library_id')
        // } 
        if(sessionStorage.getItem('meeting_place_id') && sessionStorage.getItem('schedule_library_id')) {
            sessionStorage.removeItem('meeting_place_id')
            sessionStorage.removeItem('schedule_library_id')
        }
        sessionStorage.setItem('meeting_place_id', this.meeting_place_id)
        sessionStorage.setItem('schedule_library_id', this.schedule_library_id)
        //else {
        //     meeting_place_id = this.date_line[index].meeting_place_id
        //     schedule_library_id = this.date_line[index].schedule_library_id
        // }
        document.removeEventListener("scroll", this.listenerFunction);
    },
}
</script>
<style lang='less' scoped>
@Color:var(--themecolor);

/deep/.van-empty,/deep/.van-loading{
    position: absolute;
    top: 28%;
    left:50%;
    transform:translateX(-50%);
}
/deep/.van-empty{
    width: 100%;
    .van-empty__image{
        width: 3rem;
        height: 3rem;
    }
}
/deep/.van-loading {
    top: 60%;
}
.schedule{
    width: 100%;
    height: 100%;
    position: absolute;
    background: #f2f6f8;
    overflow: hidden;
    overflow-y: scroll;
    top:0;
    bottom:0;
    left:0;
    .schedule-top{
        background: #fff;
    }
    .top{
        padding: .14rem .16rem;
        width: 100%;
        /deep/.van-search{
            padding: 0px;
            .van-search__content--round{
                border-radius:.03rem;
                background-color:#F7F8FA;
            }
            .van-search__content{
                .van-cell{
                    color: #8F9DAF;
                    .van-icon{
                        font-weight: bold;
                    }
                }
                .van-field__body{
                    .van-field__control{
                        color:#8F9DAF;
                        padding-left: .1rem;
                    }
                }
            }
        }
    }
    .tabs{
        width: 100%;
        height: .6rem;
        display: -webkit-box;
        overflow-x: scroll;
        overflow-y: hidden;
        margin-left: .16rem;
        &::-webkit-scrollbar{
            height: 0px;
        }
        .tab-box{
            width: auto;
            height: .5rem;
            border-radius: .1rem;
            overflow: hidden;
            background: #F7F8FA;
            margin-right: .12rem;
            box-sizing: border-box;
            display: flex;
            padding: .06rem .11rem;
            flex-direction: column;
            justify-content: center;
            .week{
                font-size: .12rem;
                color: #8F9DAF;
            }
            .tab-title{
                font-size: .15rem;
                color:#1F5129 ;
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
                // font-family: 'DinMedium';
                max-width: .8rem;
                display: block;
            }
            &:last-child{
                margin-right: .27rem;
            }
        }
        
        .tab-active{
            background: @Color;
            .week{
                color: #fff;
            }
            .tab-title{
                color:#fff ;
                // font-family: 'DinBold';
                font-weight: 600;
            }
            
        }
    }
    .bottom{
        padding-top: .16rem;
        background: #f2f6f8;
        .cover_img {
            position: absolute; 
            top: 0;
            width: 100%;
            height: fit-content; 
            background: #fff;
            .img_item {
                // min-height: calc(100vh - 0.3rem);
                // background-color: red;
                img {
                    width: 100%;
                }
            }
            .btns {
                position: fixed;
                bottom: 0.25rem;
                left: 0;
                right: 0;
                margin: 0 auto;
                background-color: rgba(0, 225, 175, 0.6);
                border-radius: .35rem;
                width: 1.8rem;
                height: .35rem;
                line-height: .35rem;
                text-align: center;
                color: #fff;
                font-size: .13rem;
            }
        }
        .null{
            width: 100%;
            height: auto;
            background: #fff;
            border-radius: .1rem;
            margin-bottom: .16rem;
            .null-title{
                padding:0px .16rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: .01rem #F5F6FA solid;
                height: .44rem;
                p{
                    font-size: .18rem;
                    width: 78%;
                    // font-family: 'PingFangBold';
                    font-weight: bold;
                }
            }
            /deep/.van-empty{
                position: static;
                transform: none;
                .van-empty__image{
                    margin: .01rem 0;
                }
            }
        }
        .bottom_main{
            .session{
                width: 100%;
                height: auto;
                background: #fff;
                border-radius: .01rem;
                margin-bottom: .16rem;
                .session-title-show{
                     padding:0.1rem .16rem;
                    // display: flex;
                    // justify-content: space-between;
                    // align-items: center;
                    border-bottom: .01rem #F5F6FA solid;
                    height: .44rem;
                    p{
                        font-size: .17rem;
                        width: 78%;
                        overflow: hidden;
                        text-overflow:ellipsis;
                        white-space: nowrap;
                        // font-family: 'PingFangBold';
                        font-weight: bold;
                    }
                    .title-right{
                        float: right;
                        display: flex;
                        align-items: center;
                        // margin-left:0.1rem ;
                        span{
                            font-size: .12rem;
                            margin-right: .08rem;
                            color: #8F9DAF;
                        }
                    }
                }
                .session-title{
                    padding:0.1rem .16rem;
                    // display: flex;
                    // justify-content: space-between;
                    // align-items: center;
                    border-bottom: .01rem #F5F6FA solid;
                    // height: .44rem;
                    p{
                        font-size: .17rem;
                        // width: 78%;
                        // overflow: hidden;
                        // text-overflow:ellipsis;
                        // white-space: nowrap;
                        // font-family: 'PingFangBold';
                        font-weight: bold;
                        word-break: break-all;
                    }
                    .title-right{
                        float: right;
                        display: flex;
                        align-items: center;
                        margin-left:0.1rem ;
                        span{
                            font-size: .12rem;
                            margin-right: .08rem;
                            color: #8F9DAF;
                        }
                    }
                }
                .session-main{
                    padding:.24rem 0;
                    display: -webkit-box;
                    overflow-x: scroll;
                    overflow-y: hidden;
                    margin-left: .16rem;
                    margin-right: .01rem;
                    &::-webkit-scrollbar{
                        height: 0px;
                    }
                    .session-item{
                        width: 1.66rem;
                        height: 1.66rem;
                        box-sizing: border-box;
                        padding-right: .22rem;
                        .pad-box{
                            width: 100%;
                            height: 100%;
                            overflow: hidden;
                            border: 0.01rem solid #E1E3EE;
                            border-radius: .2rem;
                            box-shadow: 0px .02rem .06rem #E8ECF1;
                        }
                        .item-title{
                            width: 100%;
                            height: .33rem;
                            display: flex;
                            font-size:.12rem ;
                            justify-content: space-between;
                            align-items: center;
                            padding: 0 .13rem;
                            background: #f5fffe;
                            color: #1F2129;
                            border-bottom: solid .01rem #e1e2ec;
                            .title-left{
                                display: flex;
                                align-items: center;
                                span{
                                    display: flex;
                                    font-size:.13rem ;
                                    // font-family: 'DinBold';
                                    font-weight: bold;
                                }
                                .interval{
                                    margin: 0px .02rem;
                                }
                                
                            }
                        }
                        .item-main{
                            padding: .13rem;
                           .meeting-title{
                               height: .75rem;
                                p{
                                    font-size: .14rem;
                                    color: #505D6F;
                                    // font-family: 'PingFangBold';
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 3;
                                    overflow: hidden;
                                    word-break: break-all;
                                }
                           } 
                           .live-btn{
                               background: #f5fffe;
                            //    border: 1px solid #D5D7E4;
                               width: 85%;
                               height:.3rem;
                               margin: .11rem auto 0 auto;
                               border-radius: .2rem;
                               display: flex;
                               align-items: center;
                               justify-content: center;
                               span{
                                    font-size: .14rem;   
                                    color: #505D6F;
                                    margin-left: .08rem;
                               }
                           }
                           .item_speaker{
                            font-size: .14rem;
                            width: 100%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow:ellipsis;
                            
                            .speaker_name{
                                word-break: break-all;
                                &::after{
                                    content: "，";
                                }
                                &:last-child{
                                    &::after{
                                    content: "";
                                    }
                                }
                            }
                           }
                        }
                        &:last-child{
                            // margin-right: 27px;
                        }
                    }
                    .live-conduct{
                        border: .01rem solid #FF5C60!important;
                        .item-title{
                            background:#fff3f3;
                            border-bottom: .01rem solid #FF5C60;
                            .title-left{
                                span{
                                   color: #FF5C60; 
                                }
                            }
                        }
                        .item-main{
                            .live-btn{
                                background: #fff3f3;
                                // border: 1px solid #FF5C60;
                                span{
                                    color: #FF5C60
                                }
                            }
                        }
                    }
                }
                .session-main-two{
                    padding:.25rem .16rem;
                    .session-item{
                        position: relative;
                        .item-title{
                            display: flex;
                            align-items: center;
                            .title-right{
                                margin-left: .05rem;
                                display: flex;
                                align-items: center;
                                padding: .05rem .14rem;
                                background: #F0F2FA;
                                border-radius: .1rem;
                                span{
                                    display: flex;
                                    font-size:.12rem ;
                                    // font-family: 'DinBold';
                                    font-weight: bold;
                                }
                            }
                        }
                        .item-main{
                            width: 100%;
                            display: flex;
                            justify-content: flex-end;
                            padding: .12rem 0px .2rem .19rem;
                            .item-main-box{
                                position: relative;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                width: 100%;
                                min-height: 1.35rem;
                                border-radius: .15rem;
                                border: .01rem solid #D5D7E4;
                                padding: .14rem .12rem;
                                box-shadow: 0px .02rem .06rem #E8ECF1;
                                .meeting-title{
                                    min-height: .6rem;
                                    box-sizing: border-box;
                                    p{
                                        font-size: .14rem;
                                        color: #1F2129;
                                        word-break: break-all;
                                        // font-family: 'PingFangBold';
                                        // font-weight: bold;
                                        // display: -webkit-box;
                                        // -webkit-box-orient: vertical;
                                        // -webkit-line-clamp: 3;
                                        // overflow: hidden;
                                    }
                                } 
                                .meeting-bottom{
                                     float: right;
                                    // position: absolute;
                                    // bottom: .14rem;
                                    // right: 0.12rem;
                                    display: inherit;
                                    width:1rem;
                                    .live-btn{
                                        
                                       background: #F5FFFE;
                                    //    border: 1px solid #D5D7E4;
                                       width: 100%;
                                       height:.3rem;
                                       border-radius: .2rem;
                                       max-width: 1rem;
                                       margin: 0.05rem 0 0 0.05rem ;
                                       display: flex;
                                       align-items: center;
                                       justify-content: center;
                                       span{
                                            font-size: .1rem;   
                                            color: #8F9DAF;
                                            margin-left: .08rem;
                                       }
                                    }
                                }
                                 .item_speaker{
                                    font-size: .14rem;
                                    // width: calc(100% - 1.1rem);
                                    margin-top:0.1rem;
                                    >span{
                                        // word-break: keep-all;
                                        vertical-align: -webkit-baseline-middle;
                                    }
                                    .speaker_name{
                                        &::after{
                                            content: "，";
                                        }
                                        &:last-child{
                                            &::after{
                                            content: "";
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &::after{
                            content: '';
                            height: 86%;
                            width: .01rem;
                            background: #D5D7E4;
                            position: absolute;
                            top: .24rem;
                            left: .05rem;
                        }
                    }
                    .live-conduct{
                        .item-title{
                            .title-right{
                                background:#fff3f1;
                                span{
                                    color: #FF5C60;
                                }
                            }
                        }
                        .item-main{
                            .item-main-box{
                                border: solid 1px #FF5C60;
                                .meeting-bottom{
                                    .live-btn{
                                        background: #fff3f3;
                                        // border: 0.5px solid #FF5C60;
                                        span{
                                            color: #FF5C60
                                        }
                                    }
                                }
                            }
                        }
                        &::after{ 
                            background: #ff7174;
                        }
                    }
                }
            }
        }
    }
}
.live-gif{
    width: .24rem;
    height: auto;
}

</style>